import { Box, styled, SxProps, Typography } from '@mui/material';
import { ReactElement, useMemo } from 'react';
import { MEETING_AUDIT_STATUS_LABELS, MEETING_STATUS_LABELS, REUSABLE_MEETING_STATUS_LABELS } from '../../constants';
import { AuditMeeting, Meeting, MeetingStatus } from '../../store';
import { Link } from 'react-router-dom';
import { isMobile } from '../../utils/screenSizeUtils';
import { CabDropdown } from '@CabComponents/CabDropdown';
import colors from '../../colors';
import { getTemplateVars } from '../../utils/scheduleUtils';
import { CabIcon } from '@CabComponents/CabIcon';
import { CabTextTokenDisplay } from '@CabComponents/CabTextTokenDisplay';
import { IoRepeatOutline } from 'react-icons/io5';

interface ComponentProps {
  meeting: Meeting | AuditMeeting;
  subtitle: ReactElement;
  navigateTo: string;
  onUpdateStatus?: (id: number, status: number) => Promise<void>;
  showStatusDisabled?: boolean;
  isAudit?: boolean;
  titleSx?: SxProps;
  containerSx?: SxProps;
  hideIcon?: boolean;
}

const ListItemContainer = styled(Box, {label: "ListItemContainer"})({
  backgroundColor: colors.white900,
  padding: 8,
  marginBottom: 2,
  justifyContent: 'space-between',
  display: 'flex',
  alignItems: 'center',
});

const meetingStatusOptions = Object.keys(MEETING_STATUS_LABELS)
  .map((statusId) => ({ value: +statusId, label: MEETING_STATUS_LABELS[Number(statusId)] }));
const reusableStatusOptions = Object.keys(REUSABLE_MEETING_STATUS_LABELS)
  .map((statusId) => ({ value: +statusId, label: REUSABLE_MEETING_STATUS_LABELS[Number(statusId)] }));
const auditStatusOptions = Object.keys(MEETING_AUDIT_STATUS_LABELS)
  .map((statusId) => ({ value: +statusId, label: MEETING_AUDIT_STATUS_LABELS[Number(statusId)] }));


const MeetingListItem = ({
  meeting, subtitle, navigateTo, onUpdateStatus, titleSx, containerSx, showStatusDisabled, isAudit, hideIcon
}: 
ComponentProps): ReactElement => {
  const questions = Object.values(meeting.questions);
  const templateVars = getTemplateVars(questions.map((q) => ({ [q.id]: q }))
    .reduce((a, b) => ({ ...a, ...b }), {}));

  const statusOptions = useMemo(() => (
    meeting.is_reusable
      ? reusableStatusOptions
      : isAudit ? auditStatusOptions : meetingStatusOptions
  ), [isAudit, meeting.is_reusable]);

  return (
    <ListItemContainer sx={containerSx}>
      <Link
        to={!isMobile() ? navigateTo : ""}
        style={{ textDecoration: 'none', flexGrow: 1}}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'bottom', justifyContent: 'space-between' }}>
          <Typography sx={titleSx}>
            <CabTextTokenDisplay
              templateVars={templateVars}
            >
              {(meeting.status === MeetingStatus.SCHEDULED 
                || isAudit) && meeting.title_booked ? meeting.title_booked : meeting.title || ''}
            </CabTextTokenDisplay>
          </Typography>
          
        </Box>
        {subtitle}
      </Link>
      {(onUpdateStatus || showStatusDisabled) && (
        <Box justifyContent='flex-end' display='flex' alignItems='center'>
          {meeting.is_reusable && !hideIcon && (
            <CabIcon alt='Reusable Meeting' Icon={IoRepeatOutline} sx={{marginRight: 1, fontSize: '20px'}}/>
          )}
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <CabDropdown
              value={meeting.status} 
              size='small'
              disabled={showStatusDisabled}
              hideArrow={showStatusDisabled}
              placeholder="Select Status"
              options={statusOptions}
              sx={{minWidth: '115px', maxWidth: '130px'}}
              onChange={ onUpdateStatus ? (e) => onUpdateStatus(meeting.id, Number(e.target.value)) 
                : () => console.log('disabled')} />
          </Box>
        </Box>
      )}
    </ListItemContainer>
  );
};

export default MeetingListItem;